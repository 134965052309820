import request from '@/utils/request'

export async function getStationMethod() {
  //console.log(params)
  return request({
    url: '/stations.authenticateMethodsSetting',
    method: 'get',
  })
}

export async function postStationMethod(data) {
  //console.log(params)
  return request({
    url: '/stations.authenticateMethodsSetting',
    method: 'post',
    data,
  })
}

export async function getStationSubjectMenu() {
  //console.log(params)
  return request({
    url: '/stations.showSubjectMenu',
    method: 'get',
  })
}

export async function postStationSubjectMenu(data) {
  //console.log(params)
  return request({
    url: '/stations.showSubjectMenu',
    method: 'post',
    data,
  })
}
