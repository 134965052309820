import { render } from "./index.vue?vue&type=template&id=18cce015&scoped=true"
import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"

import "./index.vue?vue&type=style&index=0&id=18cce015&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-18cce015"
/* hot reload */
if (module.hot) {
  script.__hmrId = "18cce015"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('18cce015', script)) {
    api.reload('18cce015', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=18cce015&scoped=true", () => {
    api.rerender('18cce015', render)
  })

}

script.__file = "src/views/healthStationSetting/index.vue"

export default script